<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="m-b-sm">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input simple-border v-model="evento.id" readonly/>
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="evento.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              view="lr"
              label="Código Único:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-input simple-border v-model="evento.codigo"/>
          </erp-s-field>
          <!--<u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaTipo" />-->
        </e-col>
      </e-row>

      <e-row>
        <e-col class="full-width">
          <erp-s-field
              view="lr"
              label="Descrição:"
              label-width="90px"
              style="display: flex;"
          >
            <textarea class="sl-textarea" v-model="evento.descricao" rows="4"></textarea>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm">
        <e-col>
          <erp-s-field
              view="lr"
              label="Ordem:"
              label-width="90px"
          >
            <erp-input simple-border v-model="evento.order"/>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save"/>
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
      </div>
      <h5 class="m-t" v-if="id">{{ id ? 'Atualizado com sucesso!' : 'Acessório registrado com sucesso!' }}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)"/>
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {ErpCheckbox, ErpInput, ErpSelect, ErpSField, WindowContent, WindowFooter} from 'uloc-vue-plugin-erp'
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import {
  find,
  newEvento,
  updateEvento
} from "@/domain/bem/services/eventos"
import WindowSuccess from "../../../../layout/window/Success"
import TimerCloseWindow from "../../../../layout/window/TimerClose"
import WindowLoading from "../../../../layout/window/Loading"

export default {
  name: 'EventoWindow',
  props: ['id', 'props'],
  data() {
    return {
      loading: this.props && this.props.id ? !!this.props.id : !!this.id,
      errors: null,
      success: false,
      status: null,
      evento: {
        nome: null,
        codigo: null,
        descricao: null,
        order: 0,
        active: true
      }
    }
  },
  computed: {
    getId() {
      return this.props && this.props.id ? this.props.id : this.id
    }
  },
  mounted() {
    this.getId && this.load()
  },
  methods: {
    load() {
      this.loading = true
      find(this.getId)
          .then(response => {
            const data = response.data
            this.evento = data
          })
          .catch(error => {
            console.log(error)
            this.errors = error
          })
          .finally(() => {
            this.loading = false
          })
    },
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.evento))
      const method = this.getId ? updateEvento(data) : newEvento(data)
      method
          .then(() => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.loading = false
                this.success = true
                this.$uloc.window.emit(this.$root.wid, 'update', this.getId)
                this.$refs.timer.start()
                // this.$uloc.window.close(this.$root.wid)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            // this.errors = error.data
            this.alertApiError(error)
            console.error(error, error.data)
            this.loading = false
            this.success = null
          })
    }
  },
  components: {
    WindowLoading,
    TimerCloseWindow,
    WindowSuccess,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    // ErpSelect,
    // ErpTabs,
    // ErpTabItem,
    WindowContent,
    // WindowBody,
    WindowFooter,
    // ErpCheckbox
  }
}
</script>

<style scoped>
.row-valores {
  padding: 10px !important;
  max-height: 100px;
  overflow-y: auto;
}
</style>
